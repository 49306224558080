import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { getServerUri } from '../utlis/config';
import { getAccessToken } from '../utlis/auth';
import LogoNew from "../assets/images/GROTrackTurquoise.png";
import Loading from './Loading';

const DeviceRegistration = () => {
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState("");
    const [ready, setReady] = useState(true);
    const [formData, setFormData] = useState({
        registration_code: '',
        device_model: '',
        email: '',
        device_serial: '',
        purchase_date: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setReady(false);
        setMessage("");
        setSuccess("");

        fetch(`${getServerUri()}/admin/device-registrations`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getAccessToken()}`
            },
            body: JSON.stringify(formData)
        })
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    setSuccess(response.message);
                    setFormData({
                        registration_code: '',
                        device_model: '',
                        email: '',
                        device_serial: '',
                        purchase_date: ''
                    });
                } else {
                    setMessage(response.message);
                }
            })
            .catch(error => {
                console.error(error);
                setMessage('Some Error Occurred. Please Try Again Later');
            })
            .finally(() => {
                setReady(true);
            });
    };

    return (
        <>
            <main className={`mx-auto container flex flex-col p-4 font-font-2 tracking-wide font-normal ${ready ? '' : 'hidden'}`}>
                <nav className="flex items-center border-b-2 border-background-6 pb-6 pt-5 mx-10">
                    <NavLink to="/">
                        <img className="h-12" src={LogoNew} alt="GRO Track" />
                    </NavLink>
                    <h1 className="mx-auto text-3xl text-text-2 font-bold">
                        DEVICE REGISTRATION
                    </h1>
                </nav>

                {message && <p className="mt-8 text-center text-red-600">{message}</p>}
                {success && <p className="mt-8 text-center text-green-600">{success}</p>}

                <section className="flex">
                    <div className="mx-auto">
                        <form onSubmit={handleSubmit} className="mt-8 flex flex-col">
                            <div className="mx-auto mt-10 flex flex-col items-end bg-background-5 px-32 py-8">
                                <div className="space-y-6">
                                    <label className="flex items-center">
                                        <p className="w-40 ml-2">Registration Code:</p>
                                        <input
                                            type="text"
                                            name="registration_code"
                                            value={formData.registration_code}
                                            onChange={handleChange}
                                            className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                                            required
                                        />
                                    </label>

                                    <label className="flex items-center">
                                        <p className="w-40 ml-2">Device Model:</p>
                                        <input
                                            type="text"
                                            name="device_model"
                                            value={formData.device_model}
                                            onChange={handleChange}
                                            className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                                            required
                                        />
                                    </label>

                                    <label className="flex items-center">
                                        <p className="w-40 ml-2">Email:</p>
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                                            required
                                        />
                                    </label>

                                    <label className="flex items-center">
                                        <p className="w-40 ml-2">Device Serial:</p>
                                        <input
                                            type="text"
                                            name="device_serial"
                                            value={formData.device_serial}
                                            onChange={handleChange}
                                            className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                                            required
                                        />
                                    </label>

                                    <label className="flex items-center cursor-pointer">
                                        <p className="w-40 ml-2">Purchase Date:</p>
                                        <input
                                            id="purchase_date"
                                            type="date"
                                            name="purchase_date"
                                            value={formData.purchase_date}
                                            onChange={handleChange}
                                            onClick={() => document.getElementById('purchase_date').showPicker()}
                                            className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none cursor-pointer"
                                            required
                                        />
                                    </label>

                                    <button
                                        type="submit"
                                        className="w-full px-8 py-1 bg-accent-1 rounded-lg text-text-3 font-bold cursor-pointer focus:outline-none"
                                    >
                                        Register Device
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </main>
            <Loading message={"Processing Registration"} ready={ready} />
        </>
    );
};

export default DeviceRegistration; 